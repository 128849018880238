import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  forwardRef,
  DestroyRef,
  SimpleChanges,
  OnChanges,
} from '@angular/core'
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  Validator,
} from '@angular/forms'
import { ValidationErrors } from '@angular/forms'
import { ControlValueMixinClass } from '@components/atoms/shared/controlValueMixinClass'
import { ErrorTypes, errorMessages } from '@components/atoms/shared/helper'
import { Subject, debounceTime, takeUntil } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'monto-input-error',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line no-use-before-define
      useExisting: forwardRef(() => InputErrorComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      // eslint-disable-next-line no-use-before-define
      useExisting: InputErrorComponent,
      multi: true,
    },
  ],
  templateUrl: './input-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputErrorComponent extends ControlValueMixinClass implements Validator, ControlValueAccessor, OnDestroy {
  @Input() customType?: ErrorTypes

  @Input() customError?: string

  @Input() debounceTime: number = 500

  @Input() invalid: boolean = false

  control?: FormControl | UntypedFormControl

  currentError: null | { message: string; type: ErrorTypes } = null

  private unsubscribeAll = new Subject()

  constructor(
    private _cdr: ChangeDetectorRef,
    private _destoryRef: DestroyRef
  ) {
    super()
  }

  override validate(form: FormControl): ValidationErrors | null {
    if (!this.control) {
      this.control = form
      this.observeErrors()
    }
    return null
  }

  ngOnDestroy() {
    this.unsubscribeAll.next(true)
    this.unsubscribeAll.complete()
  }

  observeErrors(): void {
    this.control?.events.pipe(takeUntilDestroyed(this._destoryRef), debounceTime(this.debounceTime)).subscribe(() => {
      this.checkControl()
    })
  }

  checkControl() {
    if (this.control!.invalid && !this.control!.pristine) {
      if (this.customError) {
        this.currentError = { message: this.customError, type: this.customType || 'warning' }
        this._cdr.detectChanges()
        return
      }
      this.checkErrors(this.control!.errors)
    } else {
      this.currentError = null
      this._cdr.detectChanges()
    }
  }

  checkErrors(errors: ValidationErrors | null): void {
    if (errors) {
      Object.keys(errors as object).forEach(error => {
        this.translateError(error)
      })
    }
  }

  translateError(errorType: string): void {
    const error = errorMessages[errorType]
    if (error) {
      this.currentError = error
      this._cdr.detectChanges()
      return
    }

    this.currentError = { message: 'Hubo un error con este campo, verifique su contenido', type: 'warning' }
    this._cdr.detectChanges()
  }
}
