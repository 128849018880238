export type AtomColor =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'danger'
  | 'warning'
  | 'success'
  | 'dark'
  | 'white'
  | 'info'
  | 'transparent'
  | 'light'

export type AtomSize = 'normal' | 'small' | 'large'

export type TNameIcon =
  | 'arrow-next'
  | 'shipment'
  | 'mfa'
  | 'face'
  | 'clipboard'
  | 'faceID'
  | 'cash'
  | 'warning'
  | 'clock'
  | 'qr'
  | 'box'
  | 'payment-service'
  | 'payment-service-filled'
  | 'topups'
  | 'topups-filled'
  | 'home'
  | 'home-filled'
  | 'menu'
  | 'bulb'
  | 'qr-code'
  | 'plus-circle'
  | 'close'
  | 'check'
  | 'trash'
  | 'faq'
  | 'chat'
  | 'articles'
  | 'logo'
  | 'play'
  | 'whats-new'
  | 'diamond'
  | 'gift'
  | 'card'
  | 'bell'
  | 'thumbs-up'
  | 'profile'
  | 'clock-filled'
  | 'profile-filled'
  | 'diamond-filled'
  | 'gift-filled'
  | 'thumbs-up-filled'
  | 'touch'
  | 'company'
  | 'news'
  | 'megaphone'
  | 'alert-circle'
  | 'free'
  | 'intermittence'
  | 'clock-profile'
  | 'trophy'
  | 'premium'
  | 'shop'
  | 'shop-filled'
  | 'not-found'

export type ErrorTypes = 'error' | 'warning'

export const errorMessages: { [key: string]: { message: string; type: ErrorTypes } } = {
  required: { message: 'Campo requerido', type: 'warning' },
  invalid: { message: 'Hubo un error con este campo, verifique su contenido', type: 'warning' },
  mustMatchPassword: { message: 'Las contraseña deben coincidir', type: 'warning' },
  clabeAndCardInvalid: { message: 'La CLABE es invalida para esta cuenta', type: 'warning' },
  passwordInvalid: { message: 'Ingresa una contraseña válida', type: 'warning' },
  pattern: { message: '¿Olvidaste algo?', type: 'warning' },
  emailValid: { message: 'Ingresa un correo o un celular válido', type: 'warning' },
  emailValidator: { message: 'Ingresa un correo electrónico válido', type: 'warning' },
  birthDateValid: { message: 'Debes ser mayor de 18 años', type: 'warning' },
  phoneValid: { message: 'Ingresa un celular válido', type: 'warning' },
  currencyValid: { message: 'Ingresa un importe válido', type: 'warning' },
  rfcValid: { message: 'Ingresa un RFC válido', type: 'warning' },
  notANumber: { message: 'Ingresa un número valido', type: 'warning' },
  CardNumClabeValid: {
    message: 'Asegúrate de que la CLABE contenga 18 dígitos o el número de tarjeta 16 dígitos',
    type: 'warning',
  },
}
