import { IGiftCard } from '@modules/benefit/interfaces/gift-card'
import { IPartialPayment, IUniquePayment } from '@modules/benefit/components/payment-type/payment-type.component'
import { ICard } from '@modules/benefit/interfaces/card'
import { EntityState } from '@ngrx/entity'
import { ETypePaymentMethod, ICheckoutPaymentMethod } from '@modules/checkout/checkout'

export enum EBenefitOption {
  EXTERNAL = 'external',
  INTERNAL = 'internal',
  COUPON = 'coupon',
}

export interface IBenefit {
  id?: any
  category?: string | null | string[]
  url?: string //url de la app
  name?: string
  logo?: string //logo del descuento
  legend?: string //legenda de la promocion
  card?: string | null //imagen de la tarjeta caso timhortons e innova
  background?: string //imagen de background
  background_carousel?: string //descripcion del descuento
  background_color?: string //descripcion del descuento
  logo_carousel?: string
  body_carousel?: string
  legend_gallery?: string
  show_carousel?: boolean //descripcion del descuento
  branches?: string | null //url de las sucursales,
  more_info?: string //url de mas informacion o texto de mas informacion
  closable?: boolean
  sort?: number | null
  provider_id?: string | null
  site_url?: string
  data?: Record<string, any>
  option?: EBenefitOption
  requirement?: 'all_users' | 'active_users' | 'new_users'
  status?: 'active' | 'suspended' | 'coming_soon'
  liked?: boolean
  is_premium?: boolean
  accept_payment?: boolean
}

export interface IBenefitSubscriptionPayment {
  id: string
  status: string
  payment_method: ETypePaymentMethod | 'on_demand'
  term_type: EBenefitPlanType
  due_date: string
  payment_date: string | null
  card_payment_information: {
    country: null | string
    card_id: null | string
  }
  cost: number
}

export interface IBenefitSubscription {
  id: string
  status: EBenefitPlanStatus
  term_type: EBenefitPlanType
  payment_history: IBenefitSubscriptionPayment[]
  next_payment: IBenefitSubscriptionPayment
}

export interface IBenefitSubscriptionDetails {
  relevant_subscription: null | IBenefitSubscription
  last_deprecated_subscription: null | IBenefitSubscription
  actual_cost_details: IBenefitsPlans
}

export enum EBenefitCategoryKey {
  PREMIUM = 'premium',
  FAVORITE = 'favorite',
}

export enum EBenefitPlanStatus {
  ACTIVE = 'active',
  DEPRECATED = 'deprecated',
  CANCELED = 'cancelled',
  NONE = 'none',
  UNPAID = 'unpaid',
}

export interface IBenefitCategory {
  id: string
  name: string
  key: string
  description: string
  icon: string
  benefits: IBenefit[]
}

export interface IBenefitState extends EntityState<IBenefit> {
  benefit: IBenefit | null
  loading: boolean
  idemPotency?: string
  purchase?: IBenefitPurchase | null
  success?: IBenefitSuccess | null
  cards: IGiftCard[] | ICard[] | null
  categories: IBenefitCategory[] | null
  category: IBenefitCategory | null
  confirmSupscriptionMode?: boolean
  summary?: IBenefitPlanSummary | null
  subscriptionDetails?: IBenefitSubscriptionDetails | null
}

export enum EBenefitPaymentType {
  UNIQUE = 0,
  PARTIAL = 1,
}

export enum EBenefitPlanType {
  MONTHLY = 'monthly',
  ANUAL = 'yearly',
}

export interface IBenefitsPlans {
  amount_benefits_premium: number
  calculated_amount_benefits_premium_year: number
  amount_benefits_premium_year: number
  benefits_yearly_percentage_discount: number
}

export interface IBenefitPlanSummary {
  amount: number | null
  discount: number | null
  type: EBenefitPlanType | null
  payment_type: ETypePaymentMethod | null
  paymentMethod: ICheckoutPaymentMethod | null
  plan_change?: boolean
}

export interface IBenefitPurchaseCart extends IBenefitPurchase {
  quantity: number | null
  id?: string
  name?: string
  comission?: number | null
  payment?: IUniquePayment | null
}

export interface IBenefitPurchase extends Partial<IPartialPayment>, Partial<IUniquePayment> {
  paymentType?: EBenefitPaymentType | null
  frequency?: string | null
  commission_payment_method?: number | null
  plan?: number | null
  amount?: number | null
  tax?: number | null
  name?: string | null
  cart?: IBenefitPurchaseCart[]
}

export interface ICourseCard {
  name: string
  description: string
  institution: string
  logo: string
  content: string
  btnText: string
  url: string
}

export interface IBenefitSuccess {
  title?: string
  body?: string
  image?: string
  url?: string
  urlText?: string
  codes?:
    | string[]
    | {
        name: string
        code: string
      }[]
  width?: string
  queryParams?: Record<string, string>
}

export interface IPremiumCheckoutResponse {
  commission: number
  amount: number
  total: number
  payment_date: string
}
