@if (invalid || (control?.invalid && currentError)) {
  <div class="input-errors">
    <p
      [ngClass]="{
        'input-errors-warning': currentError?.type === 'warning' || !currentError,
        'input-errors-danger': currentError?.type === 'error',
      }"
    >
      {{ currentError?.message || customError }}
    </p>
  </div>
}
