@if (label) {
  <label [for]="id" [class]="customClass">
    <div class="input-title">
      <span class="input-name">
        {{ label }}
        @if (required) {
          <span class="text-danger">*</span>
        }
      </span>
    </div>
  </label>
}
<ion-input
  [attr.maxlength]="maxlength"
  [attr.name]="name ? name : id"
  [type]="type"
  [attr.inputmode]="inputmode"
  [attr.readonly]="readonly"
  [attr.disabled]="disabled"
  [autocomplete]="autocomplete"
  [value]="value"
  (ionInput)="changed($event)"
  [id]="id"
  [placeholder]="placeholder"
  #inputref
></ion-input>
