import { UserModel } from '@modules/user/models/user.model'
import { ProfileModel } from '@modules/user/models/profile.model'

export interface IUser {
  id: string
  first_name: string
  last_name: string
  email: string
  is_active: boolean
  created_at: string
  updated_at: string
  profile_picture: string | null
  is_first_time_login: boolean
  add_sign_up_date: string
  status: UserStatus
  identifier: string
}

export interface IUserState {
  userSession: any
  user: UserModel | null
  verifyInfo: IVerifyInfo | null
  loading: boolean
  profile: ProfileModel | null
  balance: number | null | undefined
  balanceFuture: number | null | undefined
  isFirstTimeLogin: boolean
}

export interface IEditValue {
  value: string | null
  edit: boolean
}

export interface IVerifyEditableData {
  bank_id: IEditValue
  card_number: IEditValue
  cellphone: IEditValue
  clabe: IEditValue
  collaborator_id: IEditValue
  gender: IEditValue
  group_id: IEditValue
  hiring_date: IEditValue
  net_salary: IEditValue
  personal_email: IEditValue
  position: IEditValue
  extra_data: IEditValue
  sucursal: IEditValue
}

export interface ICompany {
  id: string
  name: string
  code: string
  columns?: {
    [key: string]: {
      display_name: string
      placeholder: string
      type: string
      info: string
    }
  }
}

export interface IVerifyInfo {
  data?: IVerifyEditableData
  company?: ICompany
  name?: string
  last_name?: string
  rfc?: string
  found: boolean
  allow_users?: boolean
  need_validation?: boolean
  allowed_banks?: string[]
}

export interface IDeleteUser {
  password: string
  comment: string
}

export interface IDeleteUserHubSpot {
  email: string | null
  phone: string | null
  firstname: string
  lastname: string
  company: string | null
  delete_reason: string
  more_info: string | null
}

export enum UserStatus {
  SUSPENDED = 'suspended',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  CREATED = 'created',
  VALIDATED = 'validated',
  ASSIGNED = 'assigned',
  COMPLETED = 'completed',
  PENDING = 'pending',
  CHANGED_PASSWORD = 'changed_password',
  PENDING_VALIDATION = 'pending_validation',
  PENDING_VERIFY_PHONE = 'pending_verify_phone',
  PENDING_ADMIN_VALIDATION = 'pending_admin_validation',
  SENIORITY_REQUIRED = 'seniority_required',
}

/*
 * Message Alert for each User Status
 */
export const UserStatusAlert = {
  [UserStatus.SUSPENDED]: 'Por el momento, tu cuenta esta suspendida y se reactivara proximamente',
  [UserStatus.SENIORITY_REQUIRED]:
    'No cuentas con la antigüedad mínima requerida de {minMonthsSeniorityRequired} meses, Te notificaremos cuando se active tu cuenta!',
}
